import * as React from "react";
import EmbedYouTubeVideo from "../EmbedYouTubeVideo";

export default function BackToBasixBlurb() {
  const video = (
    <EmbedYouTubeVideo
      title="Find Your #BALANCE!"
      url="https://youtu.be/8utjARAfWLo"
      wrapperHeight="100%"
    />
  );

  return (
    <div className="signup-back-to-basix">
      <div className="video-container">{video}</div>
      <div className="text-container">
        <h3 className="slogan">First, you'll get</h3>
        <h2 className="title">
          Back to Ba<span className="gold-text">Six</span>
        </h2>
        <p className="description">
          To kick start your new balanced lifestyle, your membership includes my
          6 step healthy habit formation program, Back to BaSIX! Each week,
          you'll commit to one new healthy habit. Once it becomes routine, you
          level up to the next habit! In the most fool-proof way, you'll build
          up 6 new habits so you NEVER feel overwhelmed or deprived! You'll get
          the results you want, and be ready for more!
        </p>
      </div>
    </div>
  );
}
